// LoadVRMの第一引数にはAvatarUploaderから受け取ったUUIDを渡してください
// これはHeliodor内部でこれからロードするアバターがひとつ前のものと同じなのかを判定するのに使用します。
// これによってUUIDが同じ場合、無駄なロードを行いません(第二引数はURLです)
// もしUUIDがない場合は、任意の値をアバターごとに渡してください

import type { InjectionKey } from 'vue'
/**
 * heliodor.js内で宣言されている変数を参照している。
 * Reference: public/data/heliodor_front.js
 */
declare const hel_onloaded_add: Function // eslint-disable-line camelcase
declare const SetCameraDistance: Function
declare const SetCameraHeight: Function
declare const SetCameraRotate: Function
declare const isScriptLoaded: boolean
declare const hel_isLoading: Function // eslint-disable-line camelcase
declare const hel_setText: Function // eslint-disable-line camelcase
declare const hel_SetDataFolderName: Function // eslint-disable-line camelcase
declare const LoadVRM: Function
declare const PlayAnimation: Function
declare const LoadField: Function
declare const LoadImage: Function
declare const ResizeCanvas: Function
declare const GetAvatarHeight: Function

// data/Scene/vrmviewer.jsonのmotions keyに対応
export const EMOTE_TYPE = {
  IDLE: 'Idle',
  WAVEHAND: 'Wavehand',
  RYOTEFURI: 'Ryotefuri',
  THUMBSUP: 'Thumbsup',
  DOUBLEPEACE: 'Doublepeace',
} as const
// data/HeliScript/ModelViewer.hsのPlayFacialMotionに対応
export const FACIAL_TYPE = {
  neutral: 'neutral',
  joy: 'joy',
  angry: 'angry',
  sorrow: 'sorrow',
  fun: 'fun',
} as const

/** VRM Viewerの設定値 */
export type ViewerSettings = {
  cameraHeightPc: number
  backgroundImagePc?: string
  backgroundImageSp?: string
}

type VrmViewerStateType = {
  viewerId: string
  avatarHeight: number | null // 読み込み中アバターの身長
  isCommonLoading: boolean // heliodorで何らかのデータをローディングしている時のフラグ
  isFirstLoading: boolean // heliodorの初回ローディングのフラグ
  /** 初回描画したか */
  isLoaded: boolean
  vrmLoadingTimer: ReturnType<typeof setInterval> | null
  isError: boolean
  checkLoadedTimer: ReturnType<typeof setInterval> | null
  isScriptLoaded: boolean // heliodorのjsファイルを全て読み込んだかどうか
}

const state = reactive<VrmViewerStateType>({
  viewerId: 'canvas',
  avatarHeight: null,
  isCommonLoading: false,
  isFirstLoading: false,
  isLoaded: false,
  vrmLoadingTimer: null,
  isError: false,
  checkLoadedTimer: null,
  isScriptLoaded: false,
})

const setIsScriptLoaded = () => {
  state.isScriptLoaded = true
}

const getLoadingState = () => {
  return hel_isLoading()
}

const setIngameAssetPath = (path: string) => {
  hel_SetDataFolderName(path)
}

// 初回ローディング後の処理を追加
const onloadedAdd = (callnack: Function) => {
  /* eslint-disable */
  // prettier-ignore
  if ((typeof hel_onloaded_add !== 'undefined') && hel_onloaded_add) {
    hel_onloaded_add(callnack)
  }
}

// カメラの距離変更
const changeCameraDistance = (distance: number) => {
  SetCameraDistance(distance)
}

// カメラの高さ変更
const changeCameraHeight = (height: string) => {
  SetCameraHeight(height)
}

// カメラの角度変更
const changeCameraRotate = (x: number, y: number, z: number) => {
  SetCameraRotate(x, y, z)
}

/** heliodorでローディング中かどうか */
const isLoading = computed(() => state.isFirstLoading || state.isCommonLoading)

/** hel_skyway_start */
const startSkyway = () => hel_skyway_start()

const start = () => {
  state.isError = false
  state.isCommonLoading = true

  checkLoading()

  // 2分経ってもロードが完了していなかったらエラー扱い
  state.checkLoadedTimer = setTimeout(() => {
    if (!state.isLoaded) {
      state.isError = true
      end()
    }
  }, 60000 * 2)
}

const checkLoading = (checkInterval = 500) => {
  state.isCommonLoading = true
  // 0.5秒に1回loading状態を確認
  state.vrmLoadingTimer = setInterval(() => {
    // loadingが終ったらtimer解除
    try {
      /* eslint-disable */
      // prettier-ignore
      if (
        // note: heliodorの関数実行前ににisScriptLoaded(wasm読み込みが済んだかどうか)を確認する
        state.isScriptLoaded &&
        (typeof hel_isLoading !== 'undefined') && !hel_isLoading?.()
      ) {
        state.avatarHeight = getAvatarHeight()
        end()
      }
    } catch (e) {
      console.error(e)
      state.isError = true
    }
  }, checkInterval)
}

const end = () => {
  clearInterval(state.vrmLoadingTimer!)
  clearInterval(state.checkLoadedTimer!)
  state.isCommonLoading = false
  state.vrmLoadingTimer = null
  state.isLoaded = true
}

const loadVRM = (
  uuid: string,
  filename: string,
  enableIdleMotion: boolean,
  drawingFullbody: boolean
) => {
  LoadVRM(uuid, filename, enableIdleMotion, drawingFullbody)
}

/** canvasへアバターファイルをdrop */
const loadDropVRM = (avatar: File) => {
  const canvas = document.getElementById(state.viewerId)
  const ev = new DragEvent('drop')
  const dt = new DataTransfer()
  dt.items.add(avatar)
  Object.defineProperty(ev, 'dataTransfer', {
    value: dt,
  })
  // canvasへdrop event発火
  canvas?.dispatchEvent(ev)
  console.log('drop')
}

// アニメーションを再生
const playAnimation = (animationType: string) => {
  PlayAnimation(animationType)
}

// 表情エモートを再生
const playFacialMotion = (animationType: string) => {
  PlayFacialMotion(animationType)
}

// 顔にフォーカスする
const focusFace = () => FocusFace()

// 背景モデル変更
const loadField = (filename: string) => {
  LoadField(filename)
}

// 背景画像変更
const loadBackGround = (filename: string) => {
  try {
    LoadImage(filename)
  } catch (e) {
    state.isError = true
  }
}

// canvasのリサイズ処理
const resizeCanvas = (width: number, height: number) => {
  ResizeCanvas(width, height)
}

/**
 * アバターの身長取得
 * @returns 読み込み中アバターの身長(m)
 * */
const getAvatarHeight = () => {
  const avatarHeight = GetAvatarHeight()
  // cm を m に変換して返す
  return avatarHeight ? avatarHeight / 100 : null
}

const unLoadVrmViewer = () => {
  // NOTE: これをしないと、次回のロード時にエラーが発生する
  ;(window as any).Module = undefined
  state.isLoaded = false
  state.isFirstLoading = false
  state.isScriptLoaded = false
}

export const useVrmViewer = () => {
  return {
    state: readonly(state),
    isLoading,
    setIsScriptLoaded,
    getLoadingState,
    setIngameAssetPath,
    onloadedAdd,
    checkLoading,
    changeCameraDistance,
    changeCameraHeight,
    changeCameraRotate,
    startSkyway,
    start,
    end,
    loadVRM,
    loadDropVRM,
    playAnimation,
    playFacialMotion,
    focusFace,
    loadField,
    loadBackGround,
    resizeCanvas,
    getAvatarHeight,
    unLoadVrmViewer,
  }
}

export type UseVrmViewer = ReturnType<typeof useVrmViewer>

export const vrmViewerInjectionKey: InjectionKey<UseVrmViewer> =
  Symbol('vrm-viewer')
